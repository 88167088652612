// change the theme
:root {
  --font-color: #ffffff;
  --bg-color: #000000;
}

h1, h2, h3, h4, p, ul, li, h5 {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'thunder';
  src: url('./assets/fonts/Thunder-VF.ttf');
}

@font-face {
  font-family: 'circular';
  src: url('./assets/fonts/Circular.otf');
}

$primary: white;
$secondary: #000000;
$danger: #fa5c7c;
$highlight: #F2994A;
$highlight2: #385EFC;
$button: #000000;

html{
  scroll-behavior: smooth;
}

button{
  transition: all .5s;
}

button:hover{
  background-color: #385EFC;
  color: white;
}

body{
  background-color: var(--bg-color);
  color: var(--font-color);
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-family: 'circular';
}

.home{
  max-width: 1600px;
  margin: auto;
  padding-left: 3rem;
  padding-right: 3rem;
}

.hideOndesk {
  display: none!important;
}

.sidebar{
  background-color: #F29727!important;
}

form > button{
  width: 100%!important;
  padding-left: 0!important;
  padding-right: 0!important;
}

.homeContact {
  display: flex;
}

.homeContact > input {
  flex: 1;
}

.homeContact > textarea {
  flex: 1;
  font-family: 'circular';
  background-color: #1D1D1F;
  color: white;
  border-radius: 6px;
  border: none;
  padding: 15px;
}

button{
  padding: 10px 20px 10px 20px;
  background-color: $highlight;
  color: black!important;
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
  font-size: 22px;
  border-radius: 6px;
  transition: all .5s;
}

a {
  text-decoration: none;
  color: $primary;
}

header{
  height: calc(100vh - 85px);
}

h1{
  color: $highlight;
  font-size: 6.5vw;
}

h1 > span{
  color: $primary
}

h1, h2, h3, h4, h5{
  text-transform: uppercase;
}

h2{
  font-size: 6vw;
}

.qq:hover {
  background-color: $highlight2;
}

.qq{
  transition: all .5s;
}

a, .button, .button2, h1, h2, h3, h4, .button3, h5, button {
  font-family: thunder;
}

h4{
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3vw;
}

.text-highlight{
  color: $highlight;
}

.bg-highlight2{
  background-color: $highlight2;
}

.bg-highlight{
  background-color: $highlight;
}

ul > li > a{
  transition: all .5s;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  padding: 15px 30px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.657);
}

.button2:hover{
  background-color: $highlight2;
  border-color: $highlight2;
}

.button2{
  transition: all .5s;
}

h3{
  color: $button;
  font-size: 3vw;
}

ul > li > a:hover{
  color: white;
  font-weight: bold;
 }

 ul{
  border: #ffffff29 1px solid;
  border-radius: 10px;
 }

 .badge-2{
  right: 0px;
  background: #385EFC;
  font-size: 16px;
 }

.goUp:hover{
  background-color: #385EFC;
  color: black;
  border-color: #385EFC;
}

 .rsicone{
  width: 50px;
  height: 50px;
  background: #f2994a;
  transition: all .5s;
  cursor: pointer;
 }

 .routss > a > h5 {
  transition: all .5s;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.516);
 }

 .routss > a > h5:hover {
  color: rgb(255, 255, 255);
 }

 .rsicone:hover{
  background-color: #385EFC;
 }

 .goUp{
  border-radius: 64px;
    height: 101px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px white;
    transition: all .5s;
    cursor: pointer;
 }

 .customCircle{
  background-color: $highlight2;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  right: 50px;
  top: -60px;
 }

 .rotaText{
  animation: rotation 4s infinite linear;

 }

 .big-number{
  color: $highlight2;
  font-size: 12vw;
 }

.highlight{
  color: $highlight;
}

.range-text{
  font-family: thunder;
  font-size: 1.5vw;
  color: rgba(255, 255, 255, 0.757);
}

.stepText{
  line-height: 1.2;
  height: 3em;
}

.border-primary{
  border-color: $primary;
}

.border-secondary{
  border-color: $secondary;
}

a > li{
  color: white;
}

.abo{
  padding-left: 0!important;
  padding-right: 0!important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover{
  background-color: $highlight2;
  color: white;
}

.button3:hover{
  background-color: $highlight;
  color: black;
}

.text-primary{
  color: $primary;
}

.text-secondary{
  color: $secondary;
}

.text-danger{
  color: $danger;
}

.bg-primary{
  background-color: $primary;
}

.bg-secondary{
  background-color: $secondary;
}

.bg-danger{
  background-color: $danger;
}

li{
  list-style-type: none;
  cursor: pointer;
}

.primary-button{
  color:#1D1D1F;
  background: linear-gradient(180deg, #F2BE22 0%, #F29727 100%);
  padding: 10px 20px 10px 20px;
  transition: background .4s;
  cursor: pointer;
  font-weight: bold;
}
.white-button{
  background: white;
  color: #9847FF;
  padding: 10px 20px 10px 20px;
  transition: background .4s;
  cursor: pointer;
}

.white-button:hover{
  background-color: #9847FF;
  color: white;
}

.danger-button{
  background-color: white;
  padding: 10px 20px 10px 20px;
  transition: background .4s;
  color: #1D1D1F;
  cursor: pointer;
  height: fit-content;
}

a > div{
  color: white;
}

.danger-button:hover{
  background-image: linear-gradient(180deg, #F2BE22 0%, #F29727 100%);
}

.react-select-dark > div{
  background-color: #1D1D1F;
  border: none;
  padding: 5px;
  border-radius: 5px;
  color: white;
}

.css-1dimb5e-singleValue{
  color: white!important;
}

.button{
  padding: 15px 20px 15px 20px;
  background-color: $highlight;
  color: $button;
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
  font-size: 22px;
  border-radius: 6px;
  transition: all .5s;
}

.button3{
  padding: 15px 20px 15px 20px;
  background-color: $highlight2;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
  font-size: 22px;
  border-radius: 6px;
  transition: all 0.5s;
}

.btn-white2{
  background-color: #ffffff;
  transition: all .5s;
}

.btn-white2:hover{
  background-color: #000000;
  color: white;
}

.button2{
  padding: 15px 20px 15px 20px;
  background-color: $button;
  color: $primary;
  border-radius: 6px;
  font-size: 22px;
  width: fit-content;
  font-weight: 600;
  cursor: pointer;
}

.uppercase{
  text-transform: uppercase;
}

.title{
  font-size: 35px;
}

@keyframes rotation {
  from{
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.burger-m, .burger-nav{
  display: none;
}

@media screen and (max-width: 900px) {
.ctn-pricing{
  flex-direction: column!important;
  > div{
    margin: auto;
  }
}
#contact{
  flex-direction: column!important;
  gap: 40px;
  >div{
    margin: auto;
    width: 100%;
  }
  >.justify-content-center{
    padding: 0!important;
  }
}
.testttt {
  flex-direction: column!important;
  >div{
    width: auto!important;
  }
}

}

@media screen and (max-width: 825px) {
  .ctnphone2{
    display: none!important;
  }
}

@media screen and (max-width: 1050px) {
  #demo{
    flex-direction: column-reverse!important;
    gap: 40px;
    > div {
      margin: auto;
    }
    >img{
      width: 400px!important;
      margin: auto;
    }
  }
}

@media screen and (min-width: 2000px) {
  h2{
    font-size: 80px!important;
  }

  h1{
    font-size: 150px!important;
  }

  .big-number{
    font-size: 200px!important;
  }
}

@media screen and (max-width: 1300px) {
.stepctn{
  padding: 2rem!important;
}
.stepps{
  flex-direction: column!important;
  max-width: 100%!important;
  margin: auto;
}
}

@media screen and (max-width: 600px) {
  .home{
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }

  .routss{
    display: none!important;
  }

  #faq > .button3{
    font-size: 22px!important;
  }

  .freetrialButton{
    width: 100%!important;
    padding-left: 0rem!important;
    padding-right: 0rem!important;
    text-align: center;
    font-size: 20px!important;
  }

  .range-text{
    font-size: 16px!important;
  }

  .ctn-stores{
    padding: 2rem!important;
  }

  h2{
    font-size: 35px!important;
  }

  nav{
    padding-left: 0!important;
    padding-right: 0!important;
  }

  .testttt{
    >div{
      padding: 1.5rem!important;
    }
  }

  #demo > img{
    max-width: 90%!important;
  }

  .button, .button2{
    font-size: 20px!important;
  }

  .ctn-pricing > div{
    >h5{
      font-size: 8vw!important;
      padding: 1.5rem!important;
    }
    max-width: 100%;
  }

  .p-pricng{
    padding: 1.5rem!important;
  }

  h4{
    font-size: 7vw!important;
  }

  h3{
    font-size: 7vw!important;
  }

  .rotaText{
    width: 74px!important;
  }

  .reduceMob{
    font-size: 15px!important;
  }

  .customCircle {
    width: 80px!important;
    height: 80px!important;
    top: -20px!important;
    right: 20px;

    >div{
      width: 60px!important;
      height: 60px!important;
    }
  }
}

@media screen and (max-width: 1150px) {
  .burger-m, .burger-nav{
    display: block;
  }
  .hideOndesk {
    display: block!important;
  }

  .burger-nav{
    position: absolute!important;
  }
  .ctn-prev{
    flex-direction: column!important;
    gap: 40px;
    > div{
      width: 100%!important;
      max-width: 100%!important;
    }
  }
  .burger-line{
    margin-bottom: 6px!important;
  }

  h2{
    font-size: 8vw;
  }

  h3{
    font-size: 5vw;
  }

  h1{
    font-size: 11vw;
  }

  h4{
    font-size: 5vw;
  }

  .button > svg{
    width: 2vw!important;
  }

  .button2 > svg{
    width: 2vw!important;
  }


  header >     div{
    width: 100%!important;
  }
 nav {
  align-items: flex-start!important;
  div{
    margin: 0!important;;
  }
 }
  .mainMenu{
    display: none!important;
  }
}