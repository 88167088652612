@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav{
  position: sticky;
  top:0;
  z-index: 100;
}

.m-auto{
  margin: auto;
}

/**padding**/

.p-1{
  padding: 0.375rem;
}

.p-2{
  padding: 0.75rem;
}

.stepper-custom{
  width: 100%;
  height: 5px;
  background-color: #494949;
  border-radius: 50px;
  margin-top: 20px;
}

.stepper-custom > div {
  height: 5px;
  background: linear-gradient(90deg, #F2BE22 0%, #F29727 100%);
  border-radius: 50px;
  transition: width .5s;
}

.animated-logo{
  animation-duration: 3s;
  animation-name: rotate;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.badge-eco{
  padding: 10px;
  border-radius: 8px;
  background-color: #fbff4e;
  color: #000000;
  font-size: 18px;
  text-transform: capitalize;
  position: absolute;
  right: 0px;
  top: 10px;
}

.p-3{
  padding: 1.5rem;
}

.p-4{
  padding: 3rem;
}

.p-5{
  padding: 5rem;
}

.pl-1{
  padding-left: 0.375rem;
}

.pl-2{
  padding-left: 0.75rem;
}

.pl-3{
  padding-left: 1.5rem;
}

.pl-4{
  padding-left: 3rem;
}

.pl-5{
  padding-left: 5rem;
}

.pr-1{
  padding-right: 0.375rem;
}

.pr-2{
  padding-right: 0.75rem;
}

.secondary-button{
  transition: background .4s;
}

.secondary-button:hover{
  background-color: #492CF5;
  color: white!important;
}

.pr-3{
  padding-right: 1.5rem;
}

.pr-4{
  padding-right: 3rem;
}

.pr-5{
  padding-right: 5rem;
}

.pt-1{
  padding-top: 0.375rem;
}

.pt-2{
  padding-top: 0.75rem;
}

.pt-3{
  padding-top: 1.5rem;
}

.pt-4{
  padding-top: 3rem;
}

.pt-5{
  padding-top: 5rem;
}

.pb-1{
  padding-bottom: 0.375rem;
}

.pb-2{
  padding-bottom: 0.75rem;
}

.pb-3{
  padding-bottom: 1.5rem;
}

.pb-4{
  padding-bottom: 3rem;
}

.pb-5{
  padding-bottom: 5rem;
}

/**margin**/

.m-1{
  margin: 0.375rem;
}

.m-2{
  margin: 0.75rem;
}

.m-3{
  margin: 1.5rem;
}

.m-4{
  margin: 3rem;
}

.m-5{
  margin: 5rem;
}

.ml-1{
  margin-left: 0.375rem;
}

.ml-2{
  margin-left: 0.75rem;
}

.ml-3{
  margin-left: 1.5rem;
}

.ml-4{
  margin-left: 3rem;
}

.ml-5{
  margin-left: 5rem;
}

.mr-1{
  margin-right: 0.375rem;
}

.mr-2{
  margin-right: 0.75rem;
}

.mr-3{
  margin-right: 1.5rem;
}

.mr-4{
  margin-right: 3rem;
}

.mr-5{
  margin-right: 5rem;
}

.mt-1{
  margin-top: 0.375rem;
}

.mt-2{
  margin-top: 0.75rem;
}

.mt-3{
  margin-top: 1.5rem;
}

.mt-4{
  margin-top: 3rem;
}

.mt-5{
  margin-top: 5rem;
}

.mb-1{
  margin-bottom: 0.375rem;
}

.mb-2{
  margin-bottom: 0.75rem;
}

.mb-3{
  margin-bottom: 1.5rem;
}

.mb-4{
  margin-bottom: 3rem;
}

.mb-5{
  margin-bottom: 5rem;
}

/**font**/

.font-10{
  font-size: 10px;
}

.font-11{
  font-size: 11px;
}

.font-12{
  font-size: 12px;
}

.font-13{
  font-size: 13px;
}

.font-14{
  font-size: 14px;
}

.font-15{
  font-size: 15px;
}

.font-16{
  font-size: 16px;
}

.font-17{
  font-size: 17px;
}

.font-18{
  font-size: 18px;
}

.font-19{
  font-size: 19px;
}

.font-20{
  font-size: 20px;
}

.font-21{
  font-size: 21px;
}

.font-22{
  font-size: 22px;
}

.phone-float{
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  margin-top: 20px;
  animation: float 4s infinite linear;
}

.font-big{
  font-size: 44px;
  font-family: Poppins,sans-serif;
}

.font-weight-bold{
  font-weight: bold;
}

.round{
  background: linear-gradient(180deg, #F2BE22 0%, #F29727 100%);
  color:#1D1D1F;
  min-width: 40px;
  height: 40px;
}

.dotted-line {
  border-bottom: 4px dashed #ffffff;
  width: 100%;
  height: 0;
  animation: move-dots 2s linear infinite;
  background-size: 10px 10px;
  animation: defilement 10s infinite linear;
  padding-left: 8px;
  animation-direction: reverse;
}

.dotted-line:after{
  border-bottom: 4px dashed #ffffff;
  width: 100%;
  position: absolute;
  content: '';
  height: 0;
  top: 0;
  left: 0;
  animation: defilement2 10s infinite linear;
  animation-direction: reverse;
}

.card{
  background: linear-gradient(180deg, #F2BE22 0%, #F29727 100%);
  width: 550px;
  gap: 20px;
  color: #1D1D1F;
}

@keyframes move-dots {
  to {
    background-position: 100% 0;
  }
}

/* Texte défilant */
.messagedefilant {
  display: block;
  margin: 40px auto;
  padding: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 15vw;
}

.rounded-top{
  padding: 20px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}
 
.messagedefilant div {
  position: absolute;
  min-width: 100%; /* au minimum la largeur du conteneur */
}
 
.messagedefilant div span, 
.messagedefilant div:after {
  position: relative;
  display: inline-block;
  font-size: 15vw;
  white-space: nowrap;
  top:0;
  color: transparent;
  -webkit-text-stroke: 3px;
-webkit-text-stroke-color: rgb(117, 117, 117);
}
 
.messagedefilant div span {
  animation: defilement 5s infinite linear;
}
 
.messagedefilant div:after {
  position: absolute;
  top:0; left:0;
  content:attr(data-text);
  animation: defilement2 5s infinite linear;
}
 
@keyframes defilement {
  0% { margin-left: 0; }
  100% { margin-left: -100%; }
}
 
@keyframes defilement2 {
  0% { margin-left: 100%; }
  100% { margin-left: 0%; }
}

@keyframes float {
  0% { margin-top: 20px; }
  50% { margin-top: -20px; }
  100% { margin-top: 20px; }
}

/**flex**/

.d-flex{
  display: flex;
}

.flex-column{
  flex-direction: column;
}

.flex-column-reverse{
  flex-direction: column-reverse;
}

.flex-row{
  flex-direction: row;
}

.flex-row-reverse{
  flex-direction: row-reverse;
}

.align-items-center{
  align-items: center;
}

.align-items-end{
  align-items: flex-end;
}

.align-items-start{
  align-items: flex-start;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-end{
  justify-content: flex-end;
}

.justify-content-start{
  justify-content: flex-start;
}

.justify-content-between{
  justify-content: space-between;
}

.justify-content-around{
  justify-content: space-around;
}

.align-content-center{
  align-content: center;
}

.align-content-end{
  align-content: flex-end;
}

.align-content-start{
  align-content: flex-start;
}

.align-content-between{
  align-content: space-between;
}

.align-content-around{
  align-content: space-around;
}

.flex-wrap{
  flex-wrap: wrap;
}

.flex-nowrap{
  flex-wrap: nowrap;
}

/**overflow**/

.overflow-hidden{
  overflow: hidden;
}

.overflow-auto{
  overflow: auto;
}

.overflowX-hidden{
  overflow-x: hidden;
}

.overflowY-hidden{
  overflow-y: hidden;
}

.overflowX-auto{
  overflow-x: auto;
}

.overflowY-auto{
  overflow-y: auto;
}

/** width - height **/

.w-auto{
  width: auto;
}

.w-fit{
  width: fit-content;
}

.w-100{
  width: 100%;
}

.w-75{
  width: 75%;
}

.w-50{
  width: 50%;
}

.w-25{
  width: 25%;
}

.h-auto{
  height: auto;
}

.h-fit{
  height: fit-content;
}

.h-100{
  height: 100%;
}

.h-75{
  height: 75%;
}

.h-50{
  height: 50%;
}

.h-25{
  height: 25%;
}

/** absolute **/

.position-absolute{
  position: absolute;
}

.position-fixed{
  position: fixed;
}

.position-relative{
  position: relative;
}

.left-0{
  left: 0;
}

.top-0{
  top: 0;
}

/** text-align **/

.text-start{
  text-align: left;
}

.text-center{
  text-align: center;
}

.text-end{
  text-align: end;
}

/** bg **/

.bg-white{
  background-color: white;
}

.blur-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #00000075;
  height: 100%;
  border-radius: 10px;
  backdrop-filter: blur(80px); /* Adjust the blur radius as needed */
  z-index: -1; /* Ensure the blur effect is behind the content */
}

.btn-white{
  transition: all .4s;
  cursor: pointer;
}

.rounded-hidd{
  width: 300px;
  height: 300px;
  border-radius: 400px;
  position: absolute;
  left: 50px;
  filter: blur(40px);
  color:#1D1D1F;
  background: linear-gradient(180deg, #F2BE22 0%, #F29727 100%);
}

.ctn-newpass > .position-relative{
  position: relative;
  width: fit-content;
}

.ctn-abo{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: -60px;
  display: flex;
  flex-direction: row;
}

.abo{
  text-align: center;
  width: 135px;
  transition: all .5s;
  cursor: pointer;
}

.btn-white{
  color: #474747;
}

.sidebar > ul {
  width: 100%;
}

.test-overlay{
  z-index: 100;
  left: 0;
  background-position: center;
  background-size: cover!important;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff24;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboo{
  height: 52px;
  width: inherit;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 13px;
  background: #ffffffd6;
  border-radius: 5px;
  background-image:  linear-gradient(180deg, #385efc 0%, #385efc 100%);
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #385efc;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  border: 5px solid white;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: #385ffc63 0px 0px 0px 10px;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: #385ffcd5 0px 0px 0px 15px;
  transition: all .5s;
}

.questions{
  width: 340px;
  font-size: 16px;
}

.question{
  background-color:#2a2d3e;
}

.instaCards:hover{
  filter: brightness(0.5);
}

.instaCards{
  transition: all .4s;
}

a{
  text-decoration: none;
}

.animated{
  transition: all .4s;
}

.section {
  margin-bottom: 20px;
}

.section h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.section ul li {
  margin-bottom: 5px;
}

.section ul li a {
  color: #fff;
  text-decoration: none;
}

.section ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.logo {
  /* Styles pour votre logo */
}

.legal-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.legal-links li {
  margin-right: 10px;
}

.legal-links li a {
  color: #fff;
  text-decoration: none;
}

.playButton > div{
  transition: all .4s;
  cursor: pointer;
}

.playButton > div:hover{
  opacity: .4;
}

.social-icons {
  /* Styles pour les logos de vos réseaux sociaux */
}

.support{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
}

.questions >h2{
  margin-bottom: 15px;
}

.dash-margin{
  width: calc(100% - 200px)
}

.ctn-modifs{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.privacy{
  max-width: 700px;
}

.position-relative > input{
  font-size: 18px;
  width: 300px;
}

.position-relative > input[type="number"] {
  width: 40px;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.img-right{
  position: absolute;
  transform: translate(-50%, -50%);
  right: 2px;
  top: 50%;
}

.ctn-newpass{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

form > input {
  padding: 15px;
  background-color: #1D1D1F;
  border: none;
  outline: none;
  border-radius: 6px;
  color: white;
}

.react-tel-input .form-control{
  padding: 15px 15px 15px 46px!important;
  background-color: #1D1D1F!important;
  border: none!important;
  outline: none!important;
  border-radius: 6px!important;
  color: white!important;
  width: inherit!important;
  line-height:initial!important;
  height: auto!important;
  font-size: 18px!important;
}

.flag-dropdown{
  border: none!important;
  padding-left: 10px!important;
  background-color: transparent!important;
}

.position-relative > input{
  padding: 15px;
  background-color: #1D1D1F;
  border: none;
  outline: none;
  border-radius: 6px;
  color: white;
}

form > div > input {
  padding: 15px;
  background-color: #1D1D1F;
  border: none;
  outline: none;
  border-radius: 6px;
  color: white;
}

.eye{
  transform: translate(-50%, -50%);
  right: 6px;
  top: 50%;
}

.stamped{
  width: 60px;
  min-width: 60px;
  height: 60px;
}

button{
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding-top: 15px!important;
  cursor: pointer;
  padding-bottom: 15px!important;
  border: none;
}

.btn-google{
  transition: background .4s;
}

.btn-google:hover{
  background-color: #bababa!important;
}

.sidabarlink{
  transition: all .5s;
}

.sidabarlink:hover{
  background-color: #ffffff50;
}

.image-active{
  transition: all .3s;
}

.image-active:hover{
  filter: brightness(.6);
}

ul{
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.sidebar{
  height: 100%;
  position: sticky;
  top: 0;
  z-index: 10000;
  transition: width .4s;
}

.card-2{
  flex-grow: 1;
}

.btn-white:hover{
  background-color: #F2BE22;
  color: black!important;
}

.rounded-2{
  border-radius: 15px;
}

.newsletter{
  border: none;
  outline: none;
}

.bgIphones{
  min-width: 100%;
  min-height: 100%;
  border-radius: 500px;
  background: linear-gradient(180deg, #F2BE22 0%, #F29727 100%);
}

.bg-dark{
  background-color: rgb(18, 18, 18);
}

/** border **/

.rounded{
  border-radius: 0.25rem;
}

.rounded-pill{
  border-radius: 50rem;
}

.rounded-circle{
  border-radius: 50%;
}

.border{
  border: 1px solid rgb(171, 171, 171);
}

.border-top{
  border-top: 1px solid rgb(171, 171, 171);
}

.border-bottom{
  border-bottom: 1px solid rgb(171, 171, 171);
}

.border-left{
  border-left: 1px solid rgb(171, 171, 171);
}

.border-right{
  border-right: 1px solid rgb(171, 171, 171);
}

@media screen and (max-width: 900px) {
  .dash-margin{
    width: calc(100% - 50px);
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.gallery-item {
  flex: 1 0 200px; /* Largeur minimale et maximale d'un élément */
  margin: 5px; /* Espacement entre les éléments */
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
}

.gallery-item p {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  margin: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover p {
  opacity: 1;
}

@media screen and (max-width: 1400px) {
  .header-left{
    width: 600px!important;

  }
  .header-left > .font-big{
    font-size: 30px!important;
  }
  .header-right{
    width: 400px!important;
    height: 400px!important;
    
  }
  .croissance{
    flex-direction: column-reverse;
    margin: 0!important;
  }
  .croissance-text{
    margin-bottom: 40px!important;
  }
  #prix{
    flex-direction: column;
  }
  .price{
    margin-bottom: 30px;
  }
}

.phones{
  width: 290px;
}

.parti{
  padding-top: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width: 1250px) {
  .howt{
    display: none!important;
  }
}

@media screen and (max-width: 420px) {
  .badge-eco{
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .form-log{
    margin: 20px!important;
    padding: 2rem!important;
  }

  .btn-google{
    font-size: 20px;
  }
  
}

@media screen and (max-width: 650px) {
  .font-big{
    font-size: 20px;
  }
  .imgConf{
    height: 160px;
  }
  .store{
    flex-direction: column;
    align-items: center;
  }
  .playButton{
    justify-content: center;
  }
}

.range {
  width: 100%;
  height: 15px;
  -webkit-appearance: none;
  background: #ffffff1f;
  outline: none;
  border-radius: 20px;
  overflow: hidden;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #9892ba;
  cursor: pointer;
  box-shadow: -407px 0 0 400px #f5b52c ;
}

.setupDoc{
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1150px) {
  header{
    flex-direction: column!important;
    height: auto!important;
    margin-top: 140px;
    gap: 50px;
  }

  .header-left{
    margin-left: 0!important;
    align-items: center;
    padding-left: 0!important;
    max-width: 90%;
  }

  .title{
    font-size: 30px!important;
  }

  .croissance > img{
    width: 90%!important;
  }

  .howt{
    margin-top: 50px!important;
  }

  .phone{
    max-width: 100%;
  }

  .login-ctn{
    margin: 0!important;
    justify-content: center;
  }

  .card-duo{
    max-width: 90%!important;
  }

  .card > img{
    width: 80px;
  }

  .card{
    max-width: 90%;
    flex-direction: column;
  }

  .header-right{
    max-width: 90%;
  }

  .pricing{
    max-width: calc(90% - 4rem);
    padding: 2rem!important;
  }

  .playButton{
    gap: 20px!important;
  }

  .playButton > div{
    padding-left: .5rem!important;
    padding-right: .5rem!important;
    width: 140px!important;
  }

  .graph{
    padding: 1rem!important;
  }

  .graph > .title{
    font-size: 20px!important;
  }

  .sidebar{
    position: fixed;
    left: -100px;
    top: 0;
  }

  .button-sidebar{
    right: -60px;
    left: auto!important;
  }

  .l0{
    left: 0;
  }

  .mt-2 > img{
    max-width: 100%;
  }

  .position-relative > input:not(.little-n, .spe) {
    width: 250px!important;
  }

  .price{
    max-width: 90%;
  }

  .priceList{
    margin: 0!important;
  }

  .croissance-text{
    max-width: 90%;
    margin-bottom: 40px!important;
    margin-left: 0!important;
  }

  .menu-light{
    height: 58px;
  }

  .burger-m{
    width: 100%!important;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0!important;
    padding-bottom: 10px;
  }

  .burger {
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
  }
  
  .burger-line {
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    margin-bottom: 6px;
    transition: transform 0.3s ease-in-out;
  }
  
  .open .burger-line:nth-child(1) {
    transform: rotate(45deg) translate(6px, 6px);
  }
  
  .open .burger-line:nth-child(2) {
    opacity: 0;
  }
  
  .open .burger-line:nth-child(3) {
    transform: rotate(-45deg) translate(6px, -6px);
  }
  
  .menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    list-style-type: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-top: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
  }
  
  .open .menu {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
  
  .menu li {
    padding: 5px 0;
    cursor: pointer;
  }
}

.deroule {
  animation: unfold 1s;
}

.littleBox{
  max-width: 400px;
}

@keyframes unfold {
  0% {
    max-height: 0;
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    max-height: 1000px; /* ou une valeur suffisamment grande */
    opacity: 1;
  }
}